<template>
	<div>
		<b-card title="Product Menu List">
			<b-row class="mb-2">
				<b-col cols="12" md="6">
					<b-button
						class="btn waves-effect waves-float waves-light btn-primary"
						variant="primary"
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						v-b-modal.modalCreate
					>
						Create Product Menu
					</b-button>
					<b-modal
						id="modalCreate"
						centered
						title="Page Create"
						hide-footer
					>
						<form @submit.prevent="saveItem">
							<div class="form-group">
								<label>Nama</label>
								<input type="text" class="form-control" v-model="formProduct.name">
							</div>
							<div class="form-group">
								<label>Slug</label>
								<input type="text" class="form-control" v-model="formProduct.slug">
							</div>
							<button
								variant="primary"
								class="btn waves-effect waves-float waves-light btn-primary mt-2"
							>
								Save
							</button>
						</form>
					</b-modal>
				</b-col>
				<b-col cols="12" md="6">
					<form class="form-inline justify-content-end">
						<div class="d-flex">
							<div class="position-relative">
								<input 
									type="search"
									class="form-control"
									v-model="filter.keyword" 
									placeholder="Search: Name" 
									aria-label="Search"
								>
							</div>
							<button 
								:disabled="!filter.keyword" 
								@click="filter.keyword = ''" 
								class="btn btn-outline-secondary ml-sm-2"
							>
								Reset
							</button>
						</div>
					</form>
				</b-col>
			</b-row>
			<b-table 
				striped 
				hover 
				:items="products" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				
				<template v-slot:cell(no)="{ index }">
					{{ (currentPage - 1) * 10 + index + 1 }}
				</template>

				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<b-button
							variant="link"
							class="p-0"
							v-if="checkPermission('update page')"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-modal="modalId(item.uuid)"
						>
							<b-badge 
								variant="warning"
								class="badge-glow"
							>
								<feather-icon
									:id="`invoice-row-${item.uuid}-info-icon`"
									icon="EditIcon"
									size="16"
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									v-b-tooltip.hover.bottom="'Edit Data'"
								/>
							</b-badge>
						</b-button>
					
						<b-modal
							:id="'modal_' + item.uuid"
							centered
							title="Product Info Detail"
							hide-footer
						>
							<form @submit.prevent="editItem(item)">
								<div class="form-group">
									<label>Nama</label>
									<input type="text" class="form-control" v-model="item.name">
								</div>
								<!-- <div class="form-group">
									<label>Slug</label>
									<input type="text" class="form-control" v-model="item.slug">
								</div> -->
								<button
									variant="primary"
									class="btn waves-effect waves-float waves-light btn-primary mt-2"
								>
									Save
								</button>
							</form>
						</b-modal>
						
						<b-badge 
							variant="danger"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="TrashIcon"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Delete Data'"
								v-if="checkPermission('delete product')"
								@click="deleteItem(item.uuid)"
							/>
						</b-badge>
					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	VBModal,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	directives: {
    'b-modal': VBModal,
	'b-tooltip': VBTooltip,
		Ripple,
	},
	setup(props) {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},
	data() {
		return {
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no','name', 'slug', 'actions'],
			products: [],
			formProduct: {
				name: '',
				slug: '',
			},
			filter: {
				keyword: ''
			},
			// sort: {}
		}
	},

	computed: {
		rows() {
			return this.products.length
		}
	},

	mounted() {
		// this.getItems()
	},

	methods: {
		modalId(id) {
			return 'modal_' + id;
		},
		getData(page) { //page = 1
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/product-menus', {
				params: queryParams,
			})
				.then(response => {
				this.result = response.data.data
				this.products = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
				})
		},
		saveItem() {
			this.$http.post('admin/product-menus', this.formProduct)
			.then(response => {
				this.getData()
				successNotification(this, 'Success', 'Product Menu berhasil Dibuat!')
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kesalahan teknis')
			})
			this.$nextTick(() => {
				this.$bvModal.hide('modalCreate')
			})
		},
		editItem(item) {
			item._method = 'PATCH'
			this.$http.post('admin/product-menus/' + item.uuid, item)
			.then(response => {
				successNotification(this, 'Success', 'Product Menu berhasil Diedit!')
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kesalahan teknis')
			})
			this.$nextTick(() => {
				this.$bvModal.hide('modal_' + item.uuid)
			})
		},
		deleteItem(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus Product Menu ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/product-menus/' + item)
					this.getData()
					this.products.splice(item,1)
					this.getData(this.currentPage)
					successNotification(this, 'Success', 'Product Menu berhasil dihapus!')
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Ada Kesalahan teknis')
				}
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>

<style>
	
</style>
